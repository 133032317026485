<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader title="Donations" />

    <DonationsList :striped="true" :hover="true" :downloadAllowed="false" />
  </div>
</template>

<script>
import DonationsList from 'components/donations/DonationsList';
import PageHeader from 'components/PageHeader';

export default {
  name: 'DonationsIndex',
  components: {
    DonationsList,
    PageHeader,
  },
};
</script>

<style scoped lang="scss"></style>
